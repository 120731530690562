@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css";
@import './app/theme/styles/mixins';
@import './app/theme/styles/variables';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';


@import '../node_modules/@syncfusion/ej2/styles/compatibility/material.css';
@import "../node_modules/@syncfusion/ej2-angular-popups/styles/material.css";
//@import '../node_modules/syncfusion-javascript/Content/ej/web/material/ej.web.all.compatibility.min';

// for Ribbon rendering
//@import '../node_modules/@syncfusion/ej2/grid/compatibility/material.css';
@import '../node_modules/syncfusion-javascript/Content/ej/web/default-theme/ej.web.all.compatibility.min.css';
//Raju added
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/socicon/css/socicon.css";
@import "../node_modules/metrojs/release/MetroJs.Full/MetroJs.css";
@import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
@import "../node_modules/dropzone/dist/min/dropzone.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/snazzy-info-window/dist/snazzy-info-window.css";
// @import "../node_modules/intl-tel-input/build/css/intlTelInput.css";

// @import "../node_modules/ngx-countries-dropdown/assets/styles.css";


@-webkit-keyframes spin-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
	}
	35% {
		-webkit-transform: scale3D(0, 0, 1);
	}
}

@-moz-keyframes spin-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-moz-transform: scale3D(1, 1, 1);
	}
	35% {
		-moz-transform: scale3D(0, 0, 1);
	}
}

@keyframes spin-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

h1 {
	font-size: 2rem !important;
}

body,
html {
	height: 100%;
	margin: 0;
	min-width: 900px;
}

p {
	font-family: Segoe UI;
}

a {
	font-family: Segoe UI;
}


// Container Status Ribbon Styling 
 //.e-widget.e-ribbon .e-header>.e-apptab .e-menu{
//background:#e9ebee !important;
 //}

.e-widget.e-ribbon .e-expandcollapse{
	display: none;
}
.e-widget.e-ribbon .e-header li .e-contextualtabset a {
    padding: 4px 14px;
    background-color: #e9ebee;
    background-image: url('./assets/img/filter.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 9px;
	height: 15px;
}	

.e-widget.e-ribbon .e-header .e-apptab .e-menu>li>a {
	font-size: 12px;
}
 .e-ribbon.e-js .e-header .e-active.e-contextualtabset a{
	background-color: #e9ebee;
 	background-image: url('./assets/img/filter.svg'); 
 	background-position: center; 
 	background-size: contain; 
 	//height: 5px;
 	background-repeat: no-repeat; 
 	width: 12px; 
 }
.e-ribbon.e-js .e-header .e-bottom-line.e-select.e-item{
	width: 93%;
	margin-top: 0px !important;
}
.e-widget.e-ribbon .e-header .e-contextliset{
	margin-top: 10px !important;
}
.e-ribbon.e-js>.e-header {
	height: 40px !important;
}
:host ::ng-deep .e-widget.e-menu.e-horizontal .e-mhover, .e-widget.e-menu.e-context .e-mhover, .e-widget.e-menu.e-vertical .e-mhover, .e-widget.e-menu.e-horizontal .e-mhover>.e-menulink, .e-widget.e-menu.e-context .e-mhover>.e-menulink, .e-widget.e-menu.e-vertical .e-mhover>.e-menulink, .e-widget.e-menu.e-horizontal .e-mfocused, .e-widget.e-menu.e-context .e-mfocused, .e-widget.e-menu.e-vertical .e-mfocused, .e-widget.e-menu.e-horizontal .e-mfocused>.e-menulink, .e-widget.e-menu.e-context .e-mfocused>.e-menulink, .e-widget.e-menu.e-vertical .e-mfocused>.e-menulink, .e-menu-res-wrap .e-menu-res-in-wrap .e-icon.e-check-wrap:hover {
	background-color: inherit !important;
}
.e-widget.e-menu.e-horizontal>.e-list span.e-menu-left {
    left: 65% !important;
}
:host ::ng-deep .e-widget.e-menu.e-horizontal>.e-list:hover>a span.e-arrowhead-down:before, .e-widget.e-menu.e-horizontal>.e-list:hover>span span.e-arrowhead-down:before, .e-widget.e-menu.e-horizontal>.e-list>a span.e-arrowhead-down:before, .e-widget.e-menu.e-horizontal>.e-list>span span.e-arrowhead-down:before, .e-widget.e-menu.rtl .e-list>ul .e-list:hover>span span.arrows:before, .e-widget.e-menu.rtl .e-list>ul>.e-list>a span.arrows:before, .e-widget.e-menu.rtl.e-context>.e-list:hover span.arrows:before, .e-widget.e-menu.rtl.e-context>.e-list>a span.arrows:before, .e-widget.e-menu.rtl.e-context>.e-list>span span.arrows:before, .e-widget.e-menu.rtl.e-vertical>.e-list:hover span.arrows:before, .e-widget.e-menu.rtl.e-vertical>.e-list>a span.arrows:before, .e-widget.e-menu.rtl.e-vertical>.e-list>span span.arrows:before, .e-widget.e-menu.rtl>.e-list:hover>a span.arrows, .e-widget.e-menu.rtl>.e-list:hover>span span.arrows:before, .e-widget.e-menu.rtl>.e-list>a span.arrows:before, .e-widget.e-menu.rtl>.e-list>span span.arrows{
	content: '\276F' !important;
    transform: rotate(90deg) !important;
    margin-top: 0px !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
.e-widget.e-menu.e-horizontal>.e-list.e-active>span.e-menu-arrow>span.e-arrowMenuInner {
    border-bottom: 9px solid #e9ebee !important;
}
.e-menu-wrap{
	height: 33px;
	background-color: #e9ebee !important;
	cursor: pointer !important;
	pointer-events: visible !important;
}
  .e-js.e-tab .e-header.e-addborderbottom{
  	width: 100%;
  	display: flex;
  	background-color: #e9ebee !important;
  	border: none;
  	border-bottom: 1px solid rgba(0,0,0,.12);
  }
  .e-js.e-tab .e-header.e-addborderbottom .e-active{
	padding-top: 1px;
  	border-top: none;
  }
  .e-widget.e-ribbon .e-header .e-contextliset ul{
	  padding-top: 2px;
  	border-top: none;	
  }

//  .e-widget.e-ribbon .e-header > .e-apptab .e-menu li{
//  	//padding-right: 11px;
//  }
 .e-widget.e-ribbon .e-header > .e-apptab .e-menu li:first-child{
	 width: 65%;
 }

 .e-widget.e-ribbon .e-header > .e-apptab .e-menu li:nth-child(3){
	width: 9%;
 }
 .e-widget.e-ribbon .e-header > .e-apptab .e-menu li:nth-child(4){
	width: 6%;
 }
 .e-widget.e-ribbon .e-header > .e-apptab .e-menu li:nth-child(5){
	width: 6%;
 }
 .e-lib.e-grid .e-grouptext{
	 width: 73px;
 }
  .e-widget.e-ribbon .e-innerdiv{
	  padding-left: 10%;	
	  display: inline-flex
  }

.e-widget.e-ribbon .e-groupdiv:first-child{
	//display: inline-block;
	//width: 25%;
}
.e-widget.e-ribbon .e-groupdiv{
	display: inline-block;
	width: 19%;
}
 .e-ribbon.e-js .e-content{
	 position: relative !important;
 	li{
 		display: none !important;
 	}
 	background-color: #e9ebee !important;
	//border-color: 1px solid rgba(0,0,0,.12);
 	z-index: 1;
 }

.e-list-item div:hover {
	/* grid row hover text align*/
	background-color: white !important;
}

.e-tab .e-tab-header .e-indicator {
	background: $navbar-color !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
	color: $navbar-color !important;
	text-transform: none;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
	color: #757575;
	text-transform: none;
}

// .e-grid tr::selection td{ __<<ngM2ThemingMigrationEscapedComment1>>__ 
//     background-color: white !important;
// }
// .e-grid td.e-active { 
//     background-color: yellow; 
// } 
.e-grid td.e-active {
	/* grid selection color and styles*/
	background: #AAC9DB !important;
}

.e-switch-wrapper .e-switch-on,
.e-switch-wrapper .e-switch-handle.e-switch-active {
	background: $navbar-color !important;
}

.e-radio:checked+label::before {
	background-color: #fff;
	border-color: $navbar-color !important;
}

.e-radio:checked+label::after {
	background-color: $navbar-color !important;
	color: $navbar-color !important;
}

.button-section {
	width: 8%;
	height: 70%;
	-webkit-font-smoothing: antialiased;
	text-align: center;
}

.spanstyle {
	font-weight: 900;
	font-size: large;
}


.grey_buttonstyle {
	background-color: #757575 !important;
	color: #fff !important;
	text-transform: none !important;
    border: none;
    border-radius: 3px;
    padding: 4px 13px;
	//font-size: 16px;
	// font-weight: bold;
}
.buttonstyle {
	background-color: #2b71e7 !important;
	color: #fff !important;
	text-transform: none !important;
    border: none;
    border-radius: 3px;
    padding: 4px 13px;
	//font-size: 16px;
	// font-weight: bold;
}
.buttonstyle:hover {
	background-color: #4a90f4 !important;
}
.cancel-btnStyle{
	background-color: #e9ebee !important;
    color: #2b71e7 !important;
	border-radius: 3px;
	text-decoration: none;
	text-transform: none !important;
	padding: 4px 13px;
	box-shadow: none !important;
	//font-size: 16px;
	//font-weight: bold;
}
.cancel-btnStyle:hover {
	background-color: #ffffff !important;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.marginrightclass {
	margin-right: 20px !important;
}

.container-fluid {
	height: 100% !important;
}

.e-custom-icons.e-filternone::before {
	content: '\e819';
}

.feedback {
	color: #dc3545 !important;
}

.e-add-new:before {
	content: '\e823';
}

.e-custom-icons.e-Refresh::before {
	content: '\e898';
}

.e-custom-icons.MT_Addflat::before {
	content: '\e7f9';
}

.e-custom-icons.e-freeze::before {
	content: '\e72b';
}

.e-custom-icons.e-redo-01::before {
	content: '\e703';
}

.e-listview {
	border: 1px solid gray;
}

.e-card .e-card-header {
	width: fit-content !important;
}

.e-list-item {
	background-color: $body-bg-color !important;
}

.e-schedule,
.e-timeline-month-view,
.e-child-node,
// .e-date-header-wrap table td,
.e-resource-left-td,
.e-work-cells {
	background-color: $body-bg-color !important;
	overflow: auto !important;
}

.e-listview .e-list-header {
	background-color: $body-bg-color !important;
}

.e-card {
	background-color: $body-bg-color !important;
	box-shadow: none !important;
	min-height: 0px !important;
	border: 1px solid gray;
}

.e-card-separator {
	// border-bottom: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mini-fab.mat-accent {
	background-color: $body-bg-color !important;
}

.noinitialdata.e-grid .e-emptyrow {
	// display: none !important;
}

.mat-select-panel {
	background: $body-bg-color !important;
}

.zeromarginpadding {
	margin: 0;
	padding: 0;
}

.zeromargin {
	margin: 0 !important;
}

.border {
	border: 1px solid #9ea0a2 !important;
}

.shadow-lg {
	box-shadow: 0 0rem 3rem rgba(0, 0, 0, 0.175) !important;
}

// .e-grid
// {
//     border: 1px solid #9ea0a2 !important;
// }
.dropdown-menu {
	background-color: $body-bg-color !important;
}
#cmp_sel {
	.dropdown-menu {
	//     min-width: 280px !important;
    // max-width: 300px !important;
    // width: auto !important;
	border: none !important;
	right: 20px !important;
	left: auto;
	}
	.dropdown-container{
	float: initial;
	width: 300px;
	background-color: #e9ebee;
	border: 1px solid rgba(186 ,186, 204, 72%);
	}
}

.dropdown-menu:before{
	left: auto !important;
    right: 30px;
}

.e-grid,
.e-grid .e-gridheader,
.e-ccdlg,
.e-contextmenu-wrapper ul,
.e-dlg-header-content,
.e-dlg-content,
.e-footer-content,
.e-tbar-btn,
.e-grid .e-content,
.e-toolbar .e-toolbar-items,
.e-grid .e-groupcaption,
.e-grid .e-recordplusexpand,
.e-grid .e-grouptopleftcell,
.e-grid .e-indentcell,
.e-grid .e-table,
.e-pager,
.e-grid .e-groupdroparea,
.e-grid .e-filterbarcell,
.e-pager .e-currentitem .e-pager .e-pagercontainer,
.e-grid .e-headercell,
.e-grid .e-rowcell,
.e-pager .e-pagercontainer,
.e-toolbar {
	background-color: $body-bg-color !important;
}

.e-daterangepicker,
.e-calendar-container,
.e-left-calendar,
.e-right-calendar,
.e-presets,
.e-footer {
	background-color: $body-bg-color !important;
}

.e-content {
	overflow-y: auto !important;
	height: auto ;
}


.e-grid .e-ccdlg .e-checkbox-wrapper .e-frame {
	color: $navbar-color !important;
}
.e-btn {
	font-weight: 620;
}
.e-btn.e-flat.e-primary{
	color: #fff !important;
    background-color: #2b71e7 !important;
	text-transform: capitalize;
}
.e-cancel{
	color: #2b71e7 !important;
    text-transform: capitalize !important;
}
.e-lib.e-btn.e-flat{
	text-transform: capitalize;
	color: #2b71e7;
}
.e-flat:hover{
	color: #2b71e7 !important;	
	background-color: #ffff !important;
}
.e-flat:focus, .e-flat:active{
	background-color: #ffff !important;
    border-color: transparent;
    box-shadow: none;
    color: #2b71e7 !important;
}
.e-footer-content button:first-child{
    background-color: #2b71e7 !important;
	color: #fff !important;
  }
.e-pager .e-currentitem {
	background: $navbar-color !important;
}

ejs-button:hover {
	background-color: $navbar-color !important;
	color: #fff !important;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
	background-color: $navbar-color !important;
}

.e-grid .e-ccdlg .e-checkbox-wrapper .e-frame {
	color: #ffffff !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
	width: 90% !important;
}

.e-toolbar-items {
	z-index: 1 !important;
}

.e-headercell .e-headertext {
	color: black;
	font-weight: 500 !important;
	font-size: 15px !important;
}

.mat-dialog-container {
	background-color: $body-bg-color !important;
	// overflow: hidden !important;
}

.alignstyle {
	font-size: larger;
	font-weight: 600;
	color: $hyperlinkbutton-color !important;
	cursor: pointer;
}

.zeropadding {
	padding: 0 !important;
}

.ag-theme-balham {
	background-color: $body-bg-color !important;
}

.ag-root-wrapper-body.ag-layout-normal {
	height: 100% !important;
}

.ag-theme-balham .ag-header {
	background-color: $body-bg-color !important;
	// background-image: linear-gradient( lightgrey  , $body-bg-color);
	background-image: linear-gradient(#e6e6e6, #ededed);
}

.ag-theme-balham .ag-row-even {
	background-color: $body-bg-color !important;
}

.ag-theme-balham .ag-row-odd {
	background-color: $body-bg-color !important;
}

.ag-theme-balham .ag-side-bar {
	background-color: $body-bg-color !important;
}

.cdk-overlay-pane {
	width: fit-content !important;
	min-height: 620px !important;
}

#loading-img {
	background: url(http://preloaders.net/preloaders/360/Velocity.gif) center center no-repeat;
	height: 100%;
	z-index: 20;
}

#indexdiv {
	display: none;
}

.centerdiv {
	position: absolute !important;
	// top: 57% !important;
	top:	calc(100vh - 40%) !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	font-size: x-large !important;
}

.overlay {
	background: #e9e9e9;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.5;
}

.spin-outer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.spin-container {
	height: 100%;
	width: 100%;
	display: table;
}

.spin-inner {
	vertical-align: middle;
	height: 100%;
	display: table-cell;
}

.spin-cube-grid {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	.spin-cube {
		width: 33%;
		height: 33%;
		background-color: #fff;
		float: left;
		-webkit-animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
		-moz-animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
		animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
	}
	.spin-cube1 {
		-webkit-animation-delay: 0.2s;
		-moz-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
	.spin-cube2 {
		-webkit-animation-delay: 0.3s;
		-moz-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
	.spin-cube3 {
		-webkit-animation-delay: 0.4s;
		-moz-animation-delay: 0.4s;
		animation-delay: 0.4s;
	}
	.spin-cube4 {
		-webkit-animation-delay: 0.1s;
		-moz-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}
	.spin-cube5 {
		-webkit-animation-delay: 0.2s;
		-moz-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
	.spin-cube6 {
		-webkit-animation-delay: 0.3s;
		-moz-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
	.spin-cube7 {
		-webkit-animation-delay: 0s;
		-moz-animation-delay: 0s;
		animation-delay: 0s;
	}
	.spin-cube8 {
		-webkit-animation-delay: 0.1s;
		-moz-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}
	.spin-cube9 {
		-webkit-animation-delay: 0.2s;
		-moz-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
}

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.treeview-container{
	overflow-x: hidden !important;
}
::-webkit-scrollbar-thumb {
	background: #9e9e9e;
	border: 0px none #ffffff;
	border-radius: 0px;
	&:hover {
		background: #cccccc;
	}
	&:active {
		background: #888888;
	}
}

::-webkit-scrollbar-track {
	//background: #666666;
	border: 0px none #ffffff;
	border-radius: 0px;
	// &:hover{
	//     background: #333333;
	// }
	// &:active{
	//     background: #333333; 
	// }
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.cdk-overlay-pane .tl-custom-modal {
	height: 180px;
	mat-dialog-container {
		background: $navbar-color !important;
	}
}

.tl-custom-modal {
	.mat-dialog-container {
		width: 450px !important;
		height: 80px !important;
		min-height: 80px !important;
		background: $navbar-color !important;
		color: white;
		overflow: hidden;
		.mat-form-field-label,
		.mat-focused .mat-form-field-label {
			color: white !important;
		}
		.mat-form-field-underline,
		.mat-focused .mat-form-field-underline {
			background-color: white;
		}
		#btnSearch,
		#btnClear {
			background-color: #2b72e8 !important;
			border-radius: 7px !important;
			padding: 12px 25px !important;
			line-height: 14px !important;
			color: white !important;
			margin: 0px 0px 30px!important;
			border: 0px !important;
			font-weight: bold !important;
		}
	}
}

.apply-btn-geo {
	justify-content: flex-end;
	display: flex;
	.buttonstyle {
		margin-bottom: 4px !important;
		text-transform: initial;
	}
}

.btn-disabled {
	background-color: rgba(0, 0, 0, 0.12) !important;
	border-color: transparent !important;
	box-shadow: none !important;
	color: rgba(0, 0, 0, 0.26) !important;
	pointer-events: none !important;
	cursor: default !important;
}

.row-filter {
	.form-control {
		height: calc(1.15em + 0.75rem + 2px) !important;
		margin-top: 4px;
	}
}

.geofence-dropdown .row-filter .form-control {
    height: calc(1.15em + .7rem + 2px) !important;
	margin-top: 4px;
	font-size: 12px;
}
.geofence-dropdown .treeview-header .row-filter {
     margin-bottom: 0px !important;
}
.cursor-pointer{
	cursor: pointer;
}
.geo-view .gm-style-iw .gm-ui-hover-effect {
    display: none !important;
}
.animate-row {
    animation: slide-up 0.4s ease;
  }
  
  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .color-727{
    color: #727272;
}
.text-ellipse{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#notifications-settings-tab .e-content , #baplie-u-t .e-content {
	height: auto !important;
	display: initial !important;
}
.e-excelfilter .e-searchbox span {
    cursor: pointer;
    display: flex;
    height: inherit;
    position: relative;
    right: 0%;
    top: 6px;
    width: auto;
    bottom: 10px;
    margin-bottom: 20px;
}

.e-excelfilter .e-searchcontainer {
    position: relative;
    margin-left: 0px;
    margin-top: 0px;
}
.e-excelfilter .e-searchcontainer {
background : none;
}

.e-textbox{
	display: contents;
}
.font-bold{
	font-weight: bold !important;
}
.bg-none{
    background-color: transparent !important;
}
.breadcrum-date-picker{
    background-color: #fff;
    border-radius: 50px;
}
#vesselDialog + .e-dlg-overlay {
	z-index: 0 !important;
}

#TripwiseDialog + .e-dlg-overlay{
	z-index: 0 !important;
}

#PTIResultDialog + .e-dlg-overlay{
	z-index: 0 !important;
}
.vessels-flag {
    .select-flags {
        border-bottom: 0px !important;
    }
}
#s_c{
	width: 25% !important;
	text-align: right;
}
.link-bold{
	font-weight: 500 !important;
	color: #2B71EA !important;
}

//*** Add/Edit Geofence Details Changes Start Here ****//
.geo-details-map {
    .gm-control-active.gm-fullscreen-control {
      bottom: 77px !important;
      right: 76px !important;
      border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
      z-index: 1000;
    }
    .gmnoprint {
      z-index: 10 !important;
      .gm-control-active {
        position: absolute !important;
      }
    }
    .gmnoprint {
      .gm-style-mtc:first-child {
        right: 117px;
        bottom: -132px;
        ul {
          top: -37px !important;
        }
      }
      .gm-style-mtc:last-child {
        right: 117px;
        bottom: -132px;
        ul {
          top: -37px !important;
        }
      }
    }
    .gmnoprint {
      div {
        button[title="Stop drawing"] {
          right: 295px;
          bottom: -172px;
        }
        button[title="Draw a rectangle"] {
          right: 295px;
          bottom: -172px;
        }
        button[title="Draw a circle"] {
          right: 295px;
          bottom: -172px;
        }
        button[aria-label="Draw a shape"] {
          right: 295px;
          bottom: -172px;
        }
      }
    }
  
    .gm-style {
      div {
        div {
          a {
            img {
              left: -5px !important;
              top: -8px !important;
            }
          }
        }
      }
    }
  
    button[title="Zoom in"] {
      width: 35px !important;
      height: 25px !important;
      background-color: #ffffff !important;
      top: 47px !important;
      left: -91px !important;
    }
    button[title="Zoom out"] {
      top: 47px !important;
      left: -56px !important;
      height: 25px !important;
      z-index: 999;
      width: 30px !important;
      background: #fff !important;
    }
    .gm-bundled-control {
      .gm-svpc {
        left: -25px !important;
        top: 96px !important;
      }
    }
  
    .gm-style button[title="Toggle fullscreen view"] {
      bottom: -6px !important;
      right: 53px !important;
      border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
      z-index: 1000;
    }
    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom div[draggable="false"] {
      height: inherit !important;
    }
    div[title="Show street map"] + div,
    div[title="Show satellite imagery"] + div {
      display: none !important;
    }
    agm-map {
      height: calc(100vh - 260px);
      height: 100%;
    }
  }
  .main-wrapper.menu-collapsed {
    .main {
      .geo-details-map {
          .gmnoprint {
            .gm-style-cc {
              position: relative;
              height: auto !important;
              div:last-child {
                position: fixed !important;
                background: #e1eaef;
                height: 14px;
                margin-top: -14px;
                left: 139px;
              }
            }
          }
  
          .gmnoprint.gm-style-cc {
            left: 0px;
            right: unset !important;
            background: #e1eaef;
          }
      }
    }
  }
  .main-wrapper {
    .main {
      .geo-details-map {
          .gmnoprint {
            .gm-style-cc {
              position: relative;
              height: auto !important;
              div:last-child {
                position: fixed !important;
                background: #e1eaef;
                height: 14px;
                margin-top: -14px;
                left: 322px;
              }
            }
          }
  
          .gmnoprint.gm-style-cc {
            left: 0px;
            right: unset !important;
            background: #e1eaef;
          }
      }
    }
  }
//*** Add/Edit Geofence Details Changes End Here ****//

//*** Container Details Screen Map control alignments Start Here ****//
.containerdetails_map {
	.gm-style button[title="Toggle fullscreen view"] {
	  bottom: -7px !important;
	  right: 53px !important;
	  border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
	  z-index: 1000;
	}
	.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom div[draggable="false"] {
	  height: inherit !important;
	}
	div[title="Show street map"] + div,
	div[title="Show satellite imagery"] + div {
	  display: none !important;
	}
  
	.gm-control-active.gm-fullscreen-control {
	  bottom: 77px !important;
	  right: 76px !important;
	  border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
	  z-index: 1000;
	}
	.gmnoprint:nth-of-type(3) {
	  bottom: 0px !important;
	  top: 20px !important;
	}
	.gmnoprint {
	  z-index: 10 !important;
	  .gm-control-active {
		position: absolute !important;
	  }
	}
	.gmnoprint {
	  .gm-style-mtc:first-child {
		right: 91px;
		bottom: -83px;
		ul {
		  top: -37px !important;
		}
	  }
	  .gm-style-mtc:last-child {
		right: 91px;
		bottom: -83px;
		ul {
		  top: -37px !important;
		}
	  }
	}
	.gm-style {
	  div {
		div {
		  a {
			img {
			  left: -5px !important;
			  top: -8px !important;
			}
		  }
		}
	  }
	}
  
	button[title="Zoom in"] {
	  width: 35px !important;
	  height: 25px !important;
	  background-color: #ffffff !important;
	  top: 46px !important;
	  left: -65px !important;
	}
	button[title="Zoom out"] {
	  top: 46px !important;
	  left: -30px !important;
	  height: 25px !important;
	  z-index: 999;
	  width: 30px !important;
	  background: #fff !important;
	}
	.gm-bundled-control {
	  .gm-svpc {
		left: -25px !important;
		top: 96px !important;
	  }
	}
  }
  .main-wrapper.menu-collapsed {
	.main {
	  .containerdetails_map {
			.gmnoprint {
			  .gm-style-cc {
				position: relative;
				height: auto !important;
				div:last-child {
				  position: fixed !important;
				  background: #ddedf9;
				  height: 14px;
				  margin-top: -13px;
				  left: 158px;
				}
			  }
			}
  
			.gmnoprint.gm-style-cc {
			  left: -1px;
			  right: unset !important;
			  bottom: 3px !important;
			}
		  }
	}
  }
  .main-wrapper {
	.main {
	  .containerdetails_map {
			.gmnoprint {
			  .gm-style-cc {
				position: relative;
				height: auto !important;
				div:last-child {
				  position: fixed !important;
				  background: #ddedf9;
				  height: 14px;
				  margin-top: -14px;
				  left: 341px;
				}
			  }
			}
  
			.gmnoprint.gm-style-cc {
			  left: -1px;
			  right: unset !important;
			  margin-right: 300px !important;
			  bottom: 3px !important;
			}
	  }
	}
	background: white;
  }
  //*** Container Details Screen Map control alignments End Here ****//
  .breadcrum-cu-style .agm-map-container-inner div .gm-style div:nth-of-type(2) {
    border: 0px solid transparent !important;
}
.low-opacity{
    opacity: 0.6;
}

// .e-dlg-center-center{
// 	z-index: 998 !important;
//   }

  #cmp_dr .dropdown button {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.dropdown-toggle::after{
	border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
}

// border bottom for selection
.e-ddl.e-input-group{
	border-bottom: 1px solid #0000006b !important;
  }

span.country-code + span{
    display: block !important;
    font-size: 30px !important;
    text-align: right !important;
    float: right !important;
    margin-top: -19px !important;
}
.e-pagerdropdown .e-ddl.e-input-group{
    border-bottom: 0 !important;
}
.e-ftrchk .e-frame{
    line-height: 16px !important;
}

// .e-daterangepicker.e-popup .e-range-header, .e-bigger.e-small .e-daterangepicker.e-popup .e-range-header {
// 	background: transparent !important;
// }

.e-dialog .e-dlg-resizable, .e-popup.e-popup-open.e-dialog{
	padding-bottom: 0px !important;
  }


.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
    color: rgba(0,0,0,0.87) !important
}

 .popover{
    max-width: 600px !important;
	max-height: 650px !important;
	// overflow-y: auto;
	height: 570px;
    width: 370px;
}
 .popover-header{
    font-weight: 400;
	font-size: 1.5em !important;
}
.filter-popup .e-grid ,
.filter-popup .e-grid .e-table, 
.filter-popup .e-lib.e-grid .e-gridcontent, 
.filter-popup .e-grid .e-content, 
.filter-popup .e-grid .e-rowcell
,.filter-popup .e-grid .e-gridheader, 
.filter-popup .e-lib.e-grid .e-headercell, .e-lib.e-grid .e-detailheadercell{
    background-color:white !important;
	border-color: transparent !important;
	//padding: 5px !important;
  }

  .filter-popup .e-lib.e-grid .e-headercontent{
	border-color: transparent !important;
  }

  .filter-popup .e-lib.e-grid .e-rowcell,  .filter-popup.e-lib.e-grid .e-headercell,  .filter-popup .e-lib.e-grid .e-detailheadercell{
	padding: 5px !important;
	font-size: 12px !important;
  }
  .filter-popup .e-lib.e-grid .e-gridheader tr th:first-child{
	padding-left: 5px !important;
  }
  .filter-popup .e-lib.e-grid .e-headercell, .e-lib.e-grid .e-detailheadercell{
	padding: 5px !important;
	padding-left: 5px !important;
  }
  .filter-popup .e-toolbar .e-toolbar-items{
	background: white !important;
	background-color: white !important;
  }

  .filter-popup .e-lib.e-grid .e-toolbar{
	border-top: none !important;
	background-color: white !important;
  }
  .filter-popup .e-lib.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper  .e-input-group {
	border-bottom: none !important;
    border: 1px solid #0070EF !important;
    margin-top: 5px;
    border-radius: 20px;
    padding: 0px 10px;
	width: 200px !important;

	.e-search-icon{
	   color: #0070EF;
	}

	.e-clear-icon {
	   color: #0070EF;
	}

	&:focus {
		border-bottom: none !important;
	}
  }

  .filter-popup .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
	background: none !important;
  }

  .filter-popup .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
	background: none !important;
  }

  .filter-popup .e-headertext{
	font-size: 12px !important;
	font-weight: normal !important;
  }
  .filter-popup .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check{
	color: white;
	background-color: #0070EF !important;
  }

  .e-lib .e-icons.e-none::before {
	content: '';
  }

  .custom-date-picker {
	.e-daterangepicker {
		.e-date-range-wrapper::before {
			.e-input {
				padding-left: 8px;
			} 
		}
	  }
  }
  .new-trip-screen  .e-list-item {
	background-color: #fff !important;
  }
  .new-trip-screen.e-chips-wrapper{
	display: none !important;
  }
   .e-ddt-footer{
    height: 50px;
    background: white;
}

::ng-deep .e-multiselect{
	background-color:white !important;
}