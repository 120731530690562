/**** Colors (recommended use HEX colors) ****/ 
$main-color: #242D3A; 
$default-color:#fff; 
$dark-color: #000;

$body-bg-gradient-color-1: #1e5799;
$body-bg-gradient-color-2:  #a5c9e5;

$sidebar-bg-color: #042248;
// $sidebar-bg-color:#0C3268;
$sidebar-item-active-bg-color: #637CA0;// #0099cc;


$primary-color: #024a88;
$success-color: #2d922d;
$info-color: #248dad;
$warning-color: #f79a17;
$danger-color: #bf1725;