@import "skins/blue";

$carrier-blue: #152c73;
// $body-bg-color: #e9ebee; Changed app background color to white
$body-bg-color: white;
$text-color: #242424;
$gray-dark: #292b2c;
$gray: #555;
$gray-light: #ccc;
$gray-lighter: #eceeef;
$navbar-color: $carrier-blue;
$hyperlinkbutton-color: #2c70e7;

$table-bg-active: rgba(#000, 0.075);
$table-border-width: 1px;
$table-border-color: $gray-lighter;

/**** App roots ****/
$assets-root: "~/assets/";
$images-root: $assets-root + "img/";
$fonts-root: $assets-root + "fonts/";

/**** General ****/
$font-family: "Roboto", sans-serif;

/**** Navbar ****/
$navbar-text-color: $default-color;
$navbar-height: 0px;

/**** Sidebar ****/
$sidebar-width: 253px;
$sidebar-width-collapsed: 70px;
// $sidebar-width-collapsed-updated: 74px;

/**** Widgets ****/
$widget-bar-height: 30vh;

/**** Progress ****/
$progress-border-radius: 0;
$progress-height: 20px;

/**** Fonts ****/
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;
$font-bolder: 700;
$font-ultraBold: 900;

/**** Social icons ****/
$facebook-color: #3b5998;
$twitter-color: #55acee;
$instagram-color: #8a3ab9;
$pinterest-color: #c92228;
$google-color: #dd4b39;
$linkedin-color: #0177b5;
$github-color: #6b6b6b;
$stackoverflow-color: #2f96e8;
$dribble-color: #f26798;
$behace-color: #0093fa;

//--------for prevent awesome-bootstrap-checkbox errors---------
/*@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";*/
/*@import "D:/dev/IBM Jazz/SourceCode/TripLink.Web/node_modules/bootstrap/scss/variables";*/
/*@import "variables";*/
$input-border-color: rgba(#000, 0.15);
$enable-transitions: true;
$input-color: $gray;
$input-bg-disabled: $gray-lighter;
$brand-primary: $primary-color;
$brand-success: $success-color;
$brand-info: $info-color;
$brand-warning: $warning-color;
$brand-danger: $danger-color;
$brand-inverse: $gray-dark;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 4,
    ),
    7: (
      $spacer * 5,
    ),
  ),
  $spacers
);

//Set variables to be accessible for JS
@import "../../../../node_modules/sass-to-js/sass/sass-to-js";

$variablesMap: (
  main-color: $main-color,
  default-color: $default-color,
  dark-color: $dark-color,
  primary-color: $primary-color,
  success-color: $success-color,
  warning-color: $warning-color,
  danger-color: $danger-color,
  info-color: $info-color,
  sidebar-bg-color: #152c73,
  gray: $gray,
  gray-light: $gray-light,
);

body::after {
  content: sassToJs($variablesMap);
  display: none;
}

tl-phonenumberwithcontrycode {
  .iti--allow-dropdown {
    .iti__flag-container {
      .iti__selected-flag {
        padding: 0px !important;
        .iti__flag {
          background-color: unset !important;
          box-shadow: unset !important;
          width: 20px !important;
          height: 14px !important;
          background-attachment: local;
          position: relative;
          bottom: -2%;
          .iti__ar {
            background-position: unset !important;
          }
        }
        .iti__arrow {
          //margin-left: 8px !important;
        }
      }
    }
  }
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: unset !important;
}
